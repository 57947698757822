import axios from '@/common/axios'
import qs from 'qs'

export function saveWaterCheckReport (data) {
  return axios({
    method: 'post',
    url: '/quality/waterCheckReport/save',
    data: qs.stringify(data)
  })
}

export function selectWaterCheckReportInfo (waterCheckId) {
  return axios({
    method: 'get',
    url: '/quality/waterCheckReport/info/' + waterCheckId
  })
}

export function reviewWaterCheckReport (data) {
  return axios({
    method: 'put',
    url: '/quality/waterCheckReport/updateReview',
    data: qs.stringify(data)
  })
}

export function approvalWaterCheckReport (data) {
  return axios({
    method: 'put',
    url: '/quality/waterCheckReport/updateApprove',
    data: qs.stringify(data)
  })
}
