import axios from '@/common/axios'
import qs from 'qs'

export function addWaterCheck (data) {
  return axios({
    method: 'post',
    url: '/quality/waterCheck/add',
    data: qs.stringify(data)
  })
}

export function deleteWaterCheck (id) {
  return axios({
    method: 'delete',
    url: '/quality/waterCheck/delete/' + id
  })
}

export function updateWaterCheck (data) {
  return axios({
    method: 'put',
    url: '/quality/waterCheck/update',
    data: qs.stringify(data)
  })
}

export function selectWaterCheckInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/waterCheck/info/' + id
  })
}

export function selectWaterCheckList (query) {
  return axios({
    method: 'get',
    url: '/quality/waterCheck/list',
    params: query
  })
}

export function reviewWaterCheck (data) {
  return axios({
    method: 'put',
    url: '/quality/waterCheck/updateReview',
    data: qs.stringify(data)
  })
}
