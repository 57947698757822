<template>
  <div id="waterCheck">
    <el-dialog
      :title="waterCheckFormTitle"
      width="1200px"
      :visible.sync="waterCheckDialogVisible"
      :close-on-click-modal="false"
      @close="waterCheckDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="waterCheckFormRef"
        :model="waterCheckForm"
        :rules="waterCheckFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="取水口" prop="intakePort">
              <el-input
                v-model="waterCheckForm.intakePort"
                placeholder="请输入取水口"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="检测依据：《纯化水检测操作规程》" label-width="280px" />
          </el-col>
          <el-col :span="24">
            <el-form-item label="具体检测记录：" label-width="155px" />
          </el-col>
          <el-col :span="10">
            <el-form-item label="性状：" prop="property">
              <el-radio-group
                v-model="waterCheckForm.property"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              >
                <el-radio :label="1">
                  无色澄明，无臭无味
                </el-radio>
                <el-radio :label="2">
                  浑浊，有异臭味
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item prop="propertyIntake" label-width="0px">
              （取水口：
              <el-input
                v-model="waterCheckForm.propertyIntake"
                style="width: 120px;"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
              ）
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="酸碱度：" prop="ph1">
              取本品10ml，加甲基红指示液2滴
              <el-radio-group
                v-model="waterCheckForm.ph1"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              >
                <el-radio :label="1">
                  不显红色
                </el-radio>
                <el-radio :label="2">
                  红色
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item prop="phIntake1" label-width="0px">
              （取水口：
              <el-input
                v-model="waterCheckForm.phIntake1"
                style="width: 120px;"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
              ）；
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="ph2">
              另取10ml，加溴麝香草酚蓝指示液5滴
              <el-radio-group
                v-model="waterCheckForm.ph2"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              >
                <el-radio :label="1">
                  不显蓝色
                </el-radio>
                <el-radio :label="2">
                  蓝色
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="phIntake2" label-width="0px">
              （取水口：
              <el-input
                v-model="waterCheckForm.phIntake2"
                style="width: 120px;"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
              ）
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="硝酸盐：" prop="azotate">
              取本品5ml置试管中，于冰浴中冷却，加10%氯化钾溶液0.4ml与0.1%二苯胺硫酸溶液0.1ml，摇匀，缓缓滴加硫酸5ml，摇匀，将试管于50℃水浴中放置15分钟，溶液产生的蓝色与标准硝酸盐溶液0.3 ml，加无硝酸盐的水4.7 ml，用同一方法处理后的颜色比较，供试品管颜色
              <el-radio-group
                v-model="waterCheckForm.azotate"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              >
                <el-radio :label="1">
                  浅于
                </el-radio>
                <el-radio :label="2">
                  深于
                </el-radio>
              </el-radio-group>
              标准管颜色
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="azotateIntake">
              （取水口：
              <el-input
                v-model="waterCheckForm.azotateIntake"
                style="width: 120px;"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
              ）
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="亚硝酸盐：" prop="nitrate">
              取本品10 ml，置纳氏管中，加对氨基苯磺酰胺的盐酸溶液1 ml及盐酸萘乙二胺溶液1 ml，产生的粉红色，与标准亚硝酸盐溶液0.2 ml，加无亚硝酸盐的水9.8ml，用同一方法处理后的颜色比较，供试品管颜色
              <el-radio-group
                v-model="waterCheckForm.nitrate"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              >
                <el-radio :label="1">
                  浅于
                </el-radio>
                <el-radio :label="2">
                  深于
                </el-radio>
              </el-radio-group>
              标准管颜色
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="nitrateIntake">
              （取水口：
              <el-input
                v-model="waterCheckForm.nitrateIntake"
                style="width: 120px;"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
              ）
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="氨：" prop="ammonia1">
              取本品50ml，加碱性碘化汞钾试液2ml，放置15分钟
              <el-radio-group
                v-model="waterCheckForm.ammonia1"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              >
                <el-radio :label="1">
                  显色
                </el-radio>
                <el-radio :label="2">
                  不显色
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="ammonia2">
              如显色，与氯化铵溶液1.5ml，加无氨水48ml与碱性碘化汞钾试液2ml制成的对照液比较，供试品管颜色
              <el-radio-group
                v-model="waterCheckForm.ammonia2"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              >
                <el-radio :label="1">
                  浅于
                </el-radio>
                <el-radio :label="2">
                  深于
                </el-radio>
              </el-radio-group>
              标准管颜色
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="ammoniaIntake">
              （取水口：
              <el-input
                v-model="waterCheckForm.ammoniaIntake"
                style="width: 120px;"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
              ）
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="电导率：">
              <vxe-toolbar
                v-if="waterCheckFormTitle === '新增纯化水检测记录'
                  || waterCheckFormTitle === '修改纯化水检测记录'"
              >
                <template #buttons>
                  <vxe-button @click="insertRowEventConductivity()">
                    新增
                  </vxe-button>
                </template>
              </vxe-toolbar>
              <vxe-table
                ref="conductivityTable"
                border
                resizable
                show-overflow
                keep-source
                :data="conductivityList"
                :edit-config="{trigger: 'click', mode: 'cell'}"
              >
                <vxe-table-column
                  field="waterIntake"
                  title="取水口"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="temperature"
                  title="温度（℃）"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="conductivity"
                  title="电导率（μs/cm）"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  v-if="waterCheckFormTitle === '新增纯化水检测记录'
                    || waterCheckFormTitle === '修改纯化水检测记录'"
                  title="操作"
                  width="100"
                >
                  <template #default="{ row }">
                    <template>
                      <vxe-button @click="removeRowEventConductivity(row)">
                        删除
                      </vxe-button>
                    </template>
                  </template>
                </vxe-table-column>
              </vxe-table>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="易氧化物：" prop="easyOxide">
              取本品100ml，加稀硫酸10ml，煮沸后，加高锰酸钾滴定液（0.02mol/L）0.10ml，再煮沸10分钟
              <el-radio-group
                v-model="waterCheckForm.easyOxide"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              >
                <el-radio :label="1">
                  粉红色不完全消失
                </el-radio>
                <el-radio :label="2">
                  粉红色完全消失
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="easyOxideIntake">
              （取水口：
              <el-input
                v-model="waterCheckForm.easyOxideIntake"
                style="width: 120px;"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
              ）
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="不挥发物：">
              取本品100ml，置于105℃干燥环境中至恒重。计算公式：（105℃空蒸发皿＋杂质恒重）g—（105℃空蒸发皿恒重）g =不挥发物重量mg
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <vxe-toolbar
                v-if="waterCheckFormTitle === '新增纯化水检测记录'
                  || waterCheckFormTitle === '修改纯化水检测记录'"
              >
                <template #buttons>
                  <vxe-button @click="insertRowEventNonvolatile()">
                    新增
                  </vxe-button>
                </template>
              </vxe-toolbar>
              <vxe-table
                ref="nonvolatileTable"
                border
                resizable
                show-overflow
                keep-source
                :data="nonvolatileList"
                :edit-config="{trigger: 'click', mode: 'cell'}"
              >
                <vxe-table-column
                  field="waterIntake"
                  title="取水口"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="weight1"
                  title="（105℃空蒸发皿＋杂质恒重）g"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="weight2"
                  title="（105℃空蒸发皿恒重）g"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="weight3"
                  title="不挥发物重量mg"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  v-if="waterCheckFormTitle === '新增纯化水检测记录'
                    || waterCheckFormTitle === '修改纯化水检测记录'"
                  title="操作"
                  width="100"
                >
                  <template #default="{ row }">
                    <template>
                      <vxe-button @click="removeRowEventNonvolatile(row)">
                        删除
                      </vxe-button>
                    </template>
                  </template>
                </vxe-table-column>
              </vxe-table>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="重金属：" prop="heavyMetal">
              取本品100ml，加水19ml，蒸发至20ml，放冷，加醋酸盐缓冲溶液2ml与水适量使成25ml，加硫代乙酰胺试液2ml，摇匀，放置2分钟，与标准铅溶液1.0ml加水19ml用同一方法处理后的颜色比较，供试品管颜色
              <el-radio-group
                v-model="waterCheckForm.heavyMetal"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              >
                <el-radio :label="1">
                  浅于
                </el-radio>
                <el-radio :label="2">
                  深于
                </el-radio>
              </el-radio-group>
              标准管颜色
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="heavyMetalIntake">
              （取水口：
              <el-input
                v-model="waterCheckForm.heavyMetalIntake"
                style="width: 120px;"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
              ）
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>纯化水微生物限度检测记录</el-divider>
        <el-row>
          <el-col :span="8">
            <el-form-item label="培养基名称" prop="mediumName">
              <el-input
                v-model="waterCheckForm.mediumName"
                placeholder="请输入培养基名称"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="培养基批号" prop="mediumBatch">
              <el-input
                v-model="waterCheckForm.mediumBatch"
                placeholder="请输入培养基批号"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="培养基配制量" prop="mediumQuantity">
              <el-input
                v-model="waterCheckForm.mediumQuantity"
                placeholder="请输入培养基配制量"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="培养温度" prop="cultureTemp">
              <el-input
                v-model="waterCheckForm.cultureTemp"
                placeholder="请输入培养温度"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="培养时间" prop="cultureTime">
              <el-date-picker
                v-model="waterCheckForm.cultureTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测日期" prop="testDate">
              <el-date-picker
                v-model="waterCheckForm.testDate"
                placeholder="请选择检测日期"
                value-format="yyyy-MM-dd"
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测标准">
              <el-input
                value="1ml供试品中需氧菌总数不得过100cfu"
                readonly
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测依据">
              <el-input
                value="《纯化水检测操作规程》"
                readonly
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="waterCheckFormTitle === '新增纯化水检测记录'
              || waterCheckFormTitle === '修改纯化水检测记录'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEventWaterCheckDetail()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="waterCheckDetailTable"
            border
            resizable
            show-overflow
            keep-source
            :data="waterCheckDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="intakePort"
              title="取水口"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-colgroup title="微生物检测" header-align="center">
              <vxe-table-column
                field="cfu1"
                title="培养皿1菌落数(cfu/ml)"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="cfu2"
                title="培养皿2菌落数(cfu/ml)"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="averageCfu"
                title="平均菌落数(cfu/ml)"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
            </vxe-table-colgroup>
            <vxe-table-column
              field="checkResult"
              title="检测结果"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="waterCheckFormTitle === '新增纯化水检测记录'
                || waterCheckFormTitle === '修改纯化水检测记录'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEventWaterCheckDetail(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="空白对照" prop="blankControl">
              <el-input
                v-model="waterCheckForm.blankControl"
                placeholder="请输入空白对照"
                clearable
                :disabled="waterCheckFormTitle !== '新增纯化水检测记录'
                  && waterCheckFormTitle !== '修改纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="waterCheckForm.reviewResult"
                :disabled="waterCheckFormTitle !== '复核纯化水检测记录'
                  && waterCheckFormTitle !== '纯化水检测记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="waterCheckDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="waterCheckFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="waterCheckFormTitle === '纯化水检测记录详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="waterCheckReportFormTitle"
      width="1200px"
      :visible.sync="waterCheckReportDialogVisible"
      :close-on-click-modal="false"
      @close="waterCheckReportDialogClose"
    >
      <el-form
        ref="waterCheckReportFormRef"
        :model="waterCheckReportForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker
                v-model="waterCheckReportForm.reportDate"
                placeholder="请选择报告日期"
                value-format="yyyy-MM-dd"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="性状：无色澄明，无臭无味" prop="propertyResult" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.propertyResult"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="propertyRemarks">
              <el-input
                v-model="waterCheckReportForm.propertyRemarks"
                placeholder="请输入备注"
                clearable
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="酸碱度：加甲基红指示液2滴，不显红色。加溴麝香草酚蓝指示液5滴，不显蓝色" prop="phResult" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.phResult"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="phRemarks">
              <el-input
                v-model="waterCheckReportForm.phRemarks"
                placeholder="请输入备注"
                clearable
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="硝酸盐：与标准硝酸盐溶液试验相比，颜色不得更深（0.000006%）" prop="azotateResult" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.azotateResult"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="azotateRemarks">
              <el-input
                v-model="waterCheckReportForm.azotateRemarks"
                placeholder="请输入备注"
                clearable
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="亚硝酸盐：与标准亚硝酸盐溶液试验相比，颜色不得更深（0.000002%）" prop="nitrateResult" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.nitrateResult"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="nitrateRemarks">
              <el-input
                v-model="waterCheckReportForm.nitrateRemarks"
                placeholder="请输入备注"
                clearable
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="氨：与碱性碘化汞钾试液制成的对照液比较，不得更深（0.00003%）" prop="ammoniaResult" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.ammoniaResult"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="ammoniaRemarks">
              <el-input
                v-model="waterCheckReportForm.ammoniaRemarks"
                placeholder="请输入备注"
                clearable
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="电导率：依据《纯化水检测操作规程》中温度和电导率的限度（纯化水）" prop="conductivityResult" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.conductivityResult"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="conductivityRemarks">
              <el-input
                v-model="waterCheckReportForm.conductivityRemarks"
                placeholder="请输入备注"
                clearable
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="易氧化物：粉红色不完全消失" prop="easyOxideResult" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.easyOxideResult"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="easyOxideRemarks">
              <el-input
                v-model="waterCheckReportForm.easyOxideRemarks"
                placeholder="请输入备注"
                clearable
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="不挥发物：遗留的残渣不得过1mg" prop="nonvolatileResult" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.nonvolatileResult"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="nonvolatileRemarks">
              <el-input
                v-model="waterCheckReportForm.nonvolatileRemarks"
                placeholder="请输入备注"
                clearable
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="重金属：标准铅溶液试验比较，颜色不得更深（0.00001%）" prop="heavyMetalResult" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.heavyMetalResult"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="heavyMetalRemarks">
              <el-input
                v-model="waterCheckReportForm.heavyMetalRemarks"
                placeholder="请输入备注"
                clearable
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="微生物限度：1ml供试品中需氧菌总数不得过100cfu" prop="microbeResult" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.microbeResult"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="microbeRemarks">
              <el-input
                v-model="waterCheckReportForm.microbeRemarks"
                placeholder="请输入备注"
                clearable
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="综合判定" prop="comprehensiveJudge" label-width="550px">
              <el-radio-group
                v-model="waterCheckReportForm.comprehensiveJudge"
                :disabled="waterCheckReportFormTitle !== '纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="waterCheckReportForm.reviewResult"
                :disabled="waterCheckReportFormTitle !== '复核纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="waterCheckReportForm.approvalResult"
                :disabled="waterCheckReportFormTitle !== '批准纯化水检验报告'
                  && waterCheckReportFormTitle !== '纯化水检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="waterCheckReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="waterCheckReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="检测日期">
        <el-date-picker v-model="searchForm.testDate" placeholder="请选择检测日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['WATER_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="waterCheckPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="intakePort" label="取水口" />
      <el-table-column prop="mediumName" label="培养基名称" />
      <el-table-column prop="mediumBatch" label="培养基批号" />
      <el-table-column prop="mediumQuantity" label="培养基配制量" />
      <el-table-column prop="cultureTemp" label="培养温度" />
      <el-table-column prop="cultureTime" label="培养时间" />
      <el-table-column label="检测日期">
        <template slot-scope="scope">
          <span v-if="scope.row.testDate">{{ scope.row.testDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="检测标准">
        <template>1ml供试品中需氧菌总数不得过100cfu</template>
      </el-table-column>
      <el-table-column label="检测依据">
        <template>《纯化水检测操作规程》</template>
      </el-table-column>
      <el-table-column prop="blankControl" label="空白对照" />
      <el-table-column prop="tester3" label="检测人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.testerDate3">{{ scope.row.testerDate3.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer3" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate3">{{ scope.row.reviewerDate3.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['WATER_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['WATER_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['WATER_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            v-if="checkPermission(['WATER_REPORT_CHECK']) && scope.row.reportStatus !== 2 && scope.row.reportStatus !== 3"
            type="text"
            icon="el-icon-document"
            size="small"
            @click.stop="handleReportCheck(scope.$index, scope.row)"
          >
            报告检验
          </el-button>
          <el-button
            v-if="checkPermission(['WATER_REPORT_REVIEW']) && scope.row.reportStatus === 1"
            type="text"
            icon="el-icon-document"
            size="small"
            @click.stop="handleReportReview(scope.$index, scope.row)"
          >
            报告复核
          </el-button>
          <el-button
            v-if="checkPermission(['WATER_REPORT_APPROVAL']) && scope.row.reportStatus === 2"
            type="text"
            icon="el-icon-document"
            size="small"
            @click.stop="handleReportApproval(scope.$index, scope.row)"
          >
            报告批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleReportInfo(scope.$index, scope.row)"
          >
            报告详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="waterCheckPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addWaterCheck,
  deleteWaterCheck,
  updateWaterCheck,
  selectWaterCheckInfo,
  selectWaterCheckList,
  reviewWaterCheck
} from '@/api/quality/waterCheck'

import {
  saveWaterCheckReport,
  selectWaterCheckReportInfo,
  reviewWaterCheckReport,
  approvalWaterCheckReport
} from '@/api/quality/waterCheckReport'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      waterCheckDialogVisible: false,
      waterCheckFormTitle: '',
      waterCheckForm: {
        id: '',
        intakePort: '',
        property: '',
        propertyIntake: '',
        ph1: '',
        phIntake1: '',
        ph2: '',
        phIntake2: '',
        azotate: '',
        azotateIntake: '',
        nitrate: '',
        nitrateIntake: '',
        ammonia1: '',
        ammonia2: '',
        ammoniaIntake: '',
        conductivity1: '',
        conductivity2: '',
        easyOxide: '',
        easyOxideIntake: '',
        nonvolatile1: '',
        nonvolatile2: '',
        nonvolatile3: '',
        nonvolatile4: '',
        heavyMetal: '',
        heavyMetalIntake: '',
        mediumName: '',
        mediumBatch: '',
        mediumQuantity: '',
        cultureTemp: '',
        cultureTime: '',
        testDate: '',
        blankControl: '',
        reviewResult: '',
        status: '',
        waterCheckDetailJson: '',
        conductivityJson: '',
        nonvolatileJson: '',
        taskId: ''
      },
      waterCheckFormRules: {
        testDate: [{ required: true, message: '检测日期不能为空', trigger: ['blur', 'change']}]
      },
      waterCheckPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        testDate: ''
      },
      waterCheckDetailList: [],
      conductivityList: [],
      nonvolatileList: [],
      waterCheckReportDialogVisible: false,
      waterCheckReportFormTitle: '',
      waterCheckReportForm: {
        id: '',
        waterCheckId: '',
        reportDate: '',
        propertyResult: '',
        propertyRemarks: '',
        phResult: '',
        phRemarks: '',
        azotateResult: '',
        azotateRemarks: '',
        nitrateResult: '',
        nitrateRemarks: '',
        ammoniaResult: '',
        ammoniaRemarks: '',
        conductivityResult: '',
        conductivityRemarks: '',
        easyOxideResult: '',
        easyOxideRemarks: '',
        nonvolatileResult: '',
        nonvolatileRemarks: '',
        heavyMetalResult: '',
        heavyMetalRemarks: '',
        microbeResult: '',
        microbeRemarks: '',
        comprehensiveJudge: '',
        reviewResult: '',
        approvalResult: '',
        status: '',
        taskId: ''
      }
    }
  },
  created () {
    selectWaterCheckList(this.searchForm).then(res => {
      this.waterCheckPage = res
    })
  },
  methods: {
    waterCheckDialogClose () {
      this.$refs.waterCheckFormRef.resetFields()
      this.waterCheckDetailList = []
      this.conductivityList = [],
      this.nonvolatileList = []
    },
    waterCheckFormSubmit () {
      if (this.waterCheckFormTitle === '纯化水检测记录详情') {
        this.waterCheckDialogVisible = false
        return
      }
      this.$refs.waterCheckFormRef.validate(async valid => {
        if (valid) {
          this.waterCheckForm.cultureTime = this.waterCheckForm.cultureTime.join('至')
          if (this.waterCheckFormTitle === '新增纯化水检测记录') {
            this.waterCheckForm.id = ''
            this.waterCheckForm.status = 1
            this.waterCheckForm.waterCheckDetailJson = JSON.stringify(this.$refs.waterCheckDetailTable.getTableData().tableData)
            this.waterCheckForm.conductivityJson = JSON.stringify(this.$refs.conductivityTable.getTableData().tableData)
            this.waterCheckForm.nonvolatileJson = JSON.stringify(this.$refs.nonvolatileTable.getTableData().tableData)
            await addWaterCheck(this.waterCheckForm)
          } else if (this.waterCheckFormTitle === '修改纯化水检测记录') {
            this.waterCheckForm.waterCheckDetailJson = JSON.stringify(this.$refs.waterCheckDetailTable.getTableData().tableData)
            this.waterCheckForm.conductivityJson = JSON.stringify(this.$refs.conductivityTable.getTableData().tableData)
            this.waterCheckForm.nonvolatileJson = JSON.stringify(this.$refs.nonvolatileTable.getTableData().tableData)
            await updateWaterCheck(this.waterCheckForm)
          } else if (this.waterCheckFormTitle === '复核纯化水检测记录') {
            this.waterCheckForm.status = 2
            await reviewWaterCheck(this.waterCheckForm)
          }
          this.waterCheckPage = await selectWaterCheckList(this.searchForm)
          this.waterCheckDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.waterCheckFormTitle = '新增纯化水检测记录'
      this.waterCheckDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteWaterCheck(row.id)
        if (this.waterCheckPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.waterCheckPage = await selectWaterCheckList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.waterCheckFormTitle = '修改纯化水检测记录'
      this.waterCheckDialogVisible = true
      this.selectWaterCheckInfoById(row.id)
    },
    handleReview (index, row) {
      this.waterCheckFormTitle = '复核纯化水检测记录'
      this.waterCheckDialogVisible = true
      this.selectWaterCheckInfoById(row.id)
    },
    handleInfo (index, row) {
      this.waterCheckFormTitle = '纯化水检测记录详情'
      this.waterCheckDialogVisible = true
      this.selectWaterCheckInfoById(row.id)
    },
    selectWaterCheckInfoById (id) {
      selectWaterCheckInfo(id).then(res => {
        this.waterCheckForm.id = res.id
        this.waterCheckForm.intakePort = res.intakePort
        this.waterCheckForm.property = res.property
        this.waterCheckForm.propertyIntake = res.propertyIntake
        this.waterCheckForm.ph1 = res.ph1
        this.waterCheckForm.phIntake1 = res.phIntake1
        this.waterCheckForm.ph2 = res.ph2
        this.waterCheckForm.phIntake2 = res.phIntake2
        this.waterCheckForm.azotate = res.azotate
        this.waterCheckForm.azotateIntake = res.azotateIntake
        this.waterCheckForm.nitrate = res.nitrate
        this.waterCheckForm.nitrateIntake = res.nitrateIntake
        this.waterCheckForm.ammonia1 = res.ammonia1
        this.waterCheckForm.ammonia2 = res.ammonia2
        this.waterCheckForm.ammoniaIntake = res.ammoniaIntake
        this.waterCheckForm.conductivity1 = res.conductivity1
        this.waterCheckForm.conductivity2 = res.conductivity2
        this.waterCheckForm.easyOxide = res.easyOxide
        this.waterCheckForm.easyOxideIntake = res.easyOxideIntake
        this.waterCheckForm.nonvolatile1 = res.nonvolatile1
        this.waterCheckForm.nonvolatile2 = res.nonvolatile2
        this.waterCheckForm.nonvolatile3 = res.nonvolatile3
        this.waterCheckForm.nonvolatile4 = res.nonvolatile4
        this.waterCheckForm.heavyMetal = res.heavyMetal
        this.waterCheckForm.heavyMetalIntake = res.heavyMetalIntake
        this.waterCheckForm.mediumName = res.mediumName
        this.waterCheckForm.mediumBatch = res.mediumBatch
        this.waterCheckForm.mediumQuantity = res.mediumQuantity
        this.waterCheckForm.cultureTemp = res.cultureTemp
        this.waterCheckForm.cultureTime = res.cultureTime.split('至')
        this.waterCheckForm.testDate = res.testDate
        this.waterCheckForm.blankControl = res.blankControl
        this.waterCheckForm.reviewResult = res.reviewResult
        this.waterCheckForm.status = res.status
        this.waterCheckForm.taskId = res.taskId
        this.waterCheckDetailList = res.waterCheckDetailList
        this.conductivityList = res.conductivityList
        this.nonvolatileList = res.nonvolatileList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectWaterCheckList(this.searchForm).then(res => {
        this.waterCheckPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectWaterCheckList(this.searchForm).then(res => {
        this.waterCheckPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectWaterCheckList(this.searchForm).then(res => {
        this.waterCheckPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['WATER_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['WATER_REPORT_REVIEW']) && row.reportStatus === 1) {
        return 'review'
      } else if (this.checkPermission(['WATER_REPORT_APPROVAL']) && row.reportStatus === 2) {
        return 'review'
      }
      return ''
    },
    async insertRowEventWaterCheckDetail (row) {
      let { row: newRow } = await this.$refs.waterCheckDetailTable.insertAt({}, -1)
      await this.$refs.waterCheckDetailTable.setActiveRow(newRow)
    },
    removeRowEventWaterCheckDetail (row) {
      this.$refs.waterCheckDetailTable.remove(row)
    },
    async insertRowEventConductivity (row) {
      let { row: newRow } = await this.$refs.conductivityTable.insertAt({}, -1)
      await this.$refs.conductivityTable.setActiveRow(newRow)
    },
    removeRowEventConductivity (row) {
      this.$refs.conductivityTable.remove(row)
    },
    async insertRowEventNonvolatile (row) {
      let { row: newRow } = await this.$refs.nonvolatileTable.insertAt({}, -1)
      await this.$refs.nonvolatileTable.setActiveRow(newRow)
    },
    removeRowEventNonvolatile (row) {
      this.$refs.nonvolatileTable.remove(row)
    },
    waterCheckReportDialogClose () {
      this.$refs.waterCheckReportFormRef.resetFields()
    },
    async waterCheckReportFormSubmit () {
      if (this.waterCheckReportFormTitle === '纯化水检验报告') {
        this.waterCheckReportForm.status = 1
        await saveWaterCheckReport(this.waterCheckReportForm)
      } else if (this.waterCheckReportFormTitle === '复核纯化水检验报告') {
        this.waterCheckReportForm.status = 2
        await reviewWaterCheckReport(this.waterCheckReportForm)
      } else if (this.waterCheckReportFormTitle === '批准纯化水检验报告') {
        this.waterCheckReportForm.status = 3
        await approvalWaterCheckReport(this.waterCheckReportForm)
      }
      this.waterCheckPage = await selectWaterCheckList(this.searchForm)
      this.waterCheckReportDialogVisible = false
    },
    handleReportCheck (index, row) {
      this.waterCheckReportFormTitle = '纯化水检验报告'
      this.waterCheckReportDialogVisible = true
      this.waterCheckReportForm.waterCheckId = row.id
      this.selectWaterCheckReportInfoById(row.id)
    },
    handleReportReview (index, row) {
      this.waterCheckReportFormTitle = '复核纯化水检验报告'
      this.waterCheckReportDialogVisible = true
      this.waterCheckReportForm.waterCheckId = row.id
      this.selectWaterCheckReportInfoById(row.id)
    },
    handleReportApproval (index, row) {
      this.waterCheckReportFormTitle = '批准纯化水检验报告'
      this.waterCheckReportDialogVisible = true
      this.waterCheckReportForm.waterCheckId = row.id
      this.selectWaterCheckReportInfoById(row.id)
    },
    handleReportInfo (index, row) {
      this.waterCheckReportFormTitle = '纯化水检验报告详情'
      this.waterCheckReportDialogVisible = true
      this.waterCheckReportForm.waterCheckId = row.id
      this.selectWaterCheckReportInfoById(row.id)
    },
    selectWaterCheckReportInfoById (id) {
      selectWaterCheckReportInfo(id).then(res => {
        if (res) {
          this.waterCheckReportForm.id = res.id
          this.waterCheckReportForm.reportDate = res.reportDate
          this.waterCheckReportForm.propertyResult = res.propertyResult
          this.waterCheckReportForm.propertyRemarks = res.propertyRemarks
          this.waterCheckReportForm.phResult = res.phResult
          this.waterCheckReportForm.phRemarks = res.phRemarks
          this.waterCheckReportForm.azotateResult = res.azotateResult
          this.waterCheckReportForm.azotateRemarks = res.azotateRemarks
          this.waterCheckReportForm.nitrateResult = res.nitrateResult
          this.waterCheckReportForm.nitrateRemarks = res.nitrateRemarks
          this.waterCheckReportForm.ammoniaResult = res.ammoniaResult
          this.waterCheckReportForm.ammoniaRemarks = res.ammoniaRemarks
          this.waterCheckReportForm.conductivityResult = res.conductivityResult
          this.waterCheckReportForm.conductivityRemarks = res.conductivityRemarks
          this.waterCheckReportForm.easyOxideResult = res.easyOxideResult
          this.waterCheckReportForm.easyOxideRemarks = res.easyOxideRemarks
          this.waterCheckReportForm.nonvolatileResult = res.nonvolatileResult
          this.waterCheckReportForm.nonvolatileRemarks = res.nonvolatileRemarks
          this.waterCheckReportForm.heavyMetalResult = res.heavyMetalResult
          this.waterCheckReportForm.heavyMetalRemarks = res.heavyMetalRemarks
          this.waterCheckReportForm.microbeResult = res.microbeResult
          this.waterCheckReportForm.microbeRemarks = res.microbeRemarks
          this.waterCheckReportForm.comprehensiveJudge = res.comprehensiveJudge
          this.waterCheckReportForm.reviewResult = res.reviewResult
          this.waterCheckReportForm.approvalResult = res.approvalResult
          this.waterCheckReportForm.status = res.status
          this.waterCheckReportForm.taskId = res.taskId
        }
      })
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `纯化水检测记录详情${this.waterCheckForm.testDate.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
